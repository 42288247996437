<template>
  <div>
    <div class="parentbg" :style="{height: scrollerHeight}">
      <el-row v-for="(item,index) in studentList" :key="index">
        <p style="font-weight: bold;margin-top: 20px;">
          {{item.name}}[{{item.loginName}}]
        </p>
        <div style="display: flex;margin-bottom: 20px;" >
          <el-row style="flex: 2;" >
            <el-col style="display: flex;margin-top: 15px;"  v-for="(childrenitem,childrenindex) in item.tcommentScopes" :offset="2" :key="childrenindex">
              <span style="font-size: 16px;">{{childrenitem.scopeName}}</span>
              <el-rate :disabled="item.tState === '1'?true:false" style="margin-left: 40px;font-size: 16px;" v-model="childrenitem.value"></el-rate>
            </el-col>
          </el-row>
          <el-row style="flex: 4;text-align: center;">
            <el-input
              type="textarea"
              :rows="6"
              :disabled="item.tState === '1'?true:false"
              placeholder="请输入评价内容"
              v-model="item.tcomment">
            </el-input>
          </el-row>
          <div style="flex: 2;margin-left: 30px;display: flex;flex-direction: column">
            <div v-if="item.tState === '2'">
              <el-col style="margin-top: 10px;">
                <el-button type="info" size="small" @click="submitComment(index,2)" plain>暂存</el-button>
              </el-col>

              <el-col style="margin-top: 10px;">
                <el-button type="primary" size="small" @click="submitComment(index,1)"  >提交</el-button>
              </el-col>
            </div>

            <el-col v-if="item.tState === '1'" style="margin-top: 10px;">
              <el-button type="warning" size="small" plain  @click="submitComment(index,3)"  >撤销评价</el-button>
            </el-col>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>

  import { teacherAppraise} from '@/api/senate/class'
export default {

  data () {
    return {
      studentList:[],
      teacherDefaultArray:[],
      textarea:""
    }
  },
  computed: {
    scrollerHeight: function() {
      return (document.body.clientHeight - 150) + 'px';
    }
  },
  created () {
    this.studentList = JSON.parse(this.$route.query.studentList)
  },
  methods: {
    submitComment(index,type) {
      let tipsMessage = ""
      let thembtype = 0
      if(type === 1) {
        thembtype = 1
        tipsMessage = '确认提交对【'+this.studentList[index].name+'】评价吗？'
      }else if(type === 2) {
        thembtype = 2
        this.teacherAppraise(this.studentList[index],thembtype)
        return true
      }else if(type === 3){
        thembtype = 2
        tipsMessage = '确认撤销对【'+this.studentList[index].name+'】评价吗？'
      }
      this.$confirm(tipsMessage)
        .then(_ => {
          this.teacherAppraise(this.studentList[index],thembtype)
        })
        .catch(_ => {});
    },
    async teacherAppraise(row,type) {
      const res = await teacherAppraise({
        lessonId:row.lesson_id,
        studentId:row.student_id,
        tState:type,
        tcomment:row.tcomment,
        tcommentScopes:row.tcommentScopes
      })
      if(res && res.state === "success") {
        if(type === 2) {
          row.tState = "2"
        }else {
          row.tState = "1"
        }

      }
    },
  }
}
</script>

<style lang="scss" scope>
.parentbg {
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  padding: 20px;
  padding-top: 0px;
  overflow-y: scroll;
}
</style>
